<!--
 * @Author:
 * @Date: 2023-02-21 09:49:59
 * @LastEditTime: 2023-11-16 13:15:39
 * @LastEditors: 隐世浮华
 * @Description:
 * @FilePath: \edub_tob_pc\src\views\seventhPage\mine\learn\mycurriculum.vue
 *
-->
<template>
  <!-- 我的课程 -->
  <div class="foxRight">
    <div class="p-t-24 m-r-32">
      <div class="tab_box">
        <div :class="btnInfo == 0 ? 'on' : ''" class="btn_item" @click="changeTabs(0)">
          全部
        </div>
        <div :class="btnInfo == 1 ? 'on' : ''" class="btn_item" @click="changeTabs(1)">
          知识套餐
        </div>
        <div :class="btnInfo == 2 ? 'on' : ''" class="btn_item" @click="changeTabs(2)">
          精品课程
        </div>
        <div class="sliding_block" :style="`left: ${btnInfo * 132 + btnInfo * 4 + 4}px;`" />
      </div>
    </div>
    <!-- 有课程 -->
    <div v-if="list && list.length > 0" class="list">
      <div v-for="(item, index) in list" :key="index" class="item">
        <!-- :class="item.type == 1 || item.type == 16 ? 'classView' : 'courView'" -->
        <div class="classView">
          <div class="flex-c box">
            <div class="item-validPeriod">
              {{
                "课程有效期：" + calculateTheValidityPeriod(item.validityTime)
              }}
            </div>
            <div class="semicircle" />
            <img class="item-img" :src="item.img">
            <div class="course_type">
              <span v-if="item.type == 1 || item.type == 16">{{
                item.productLine == "5" ? "1V1直播" : "知识套餐"
              }}</span>
              <span v-else>精品课程</span>
            </div>
            <div class="item-centent">
              <div class="item_name-box">
                <div v-if="item.type != 1" class="item-tips elp">
                  {{ showTypeEnums[item.type] }}
                </div>
                <div class="item-text elp">
                  <img v-if="item.isHavePrivateClass && item.productLine == 3" style="
                      display: inline-block;
                      width: 62px;
                      line-height: 22px;
                      vertical-align: middle;
                      margin-right: 10px;
                    " src="@/assets/img/smallban/title.png" alt="">
                  <img v-if="item.isHavePrivateClass && item.productLine != 3" style="
                      display: inline-block;
                      width: 62px;
                      line-height: 22px;
                      vertical-align: middle;
                      margin-right: 10px;
                    " src="@/assets/img/smallban/title1.png" alt="">
                  {{ item.name }}
                </div>
              </div>
              <div class="tips">
                <!-- 知识套餐 -->
                <template v-if="item.type == 1">
                  <div v-if="item.productLine !== '5'" class="item-tips elp">
                    共{{ item.courseNum ? item.courseNum : 0 }}门课
                  </div>
                </template>
                <!-- 课程 -->
              </div>
              <!-- 讲师 -->
              <div v-if="item.type != 1 && item.type != 16" class="colleges-ter flex-c">
                <div class="ter-list">
                  <div class="terbox">
                    <el-image class="ter-img" :src="item ? item.tImg : ''" fit="cover" />
                  </div>
                  <span class="ter">讲师：{{ item ? item.terName : "" }}</span>
                </div>
              </div>
              <div v-if="item.type == 10" class="ter twoEllipsis">
                上课地点：{{ item ? item.attendPlace : "" }}
              </div>
              <div v-if="item.type != 1 && item.type != 16" class="learning_rate">
                <div v-if="item.type == 2" class="rate_item">
                  <img class="img" src="@/assets/img/homeSeventh/television2.png" alt="">
                  <div class="text">
                    <span>到课率 &nbsp; </span>
                    <span>{{ item.liveClass ? item.liveClass : 0 }}%</span>
                  </div>
                </div>
                <div v-if="item.type != 10" class="rate_item">
                  <img class="img" src="@/assets/img/homeSeventh/book.png" alt="">
                  <div class="text">
                    <span>学习率 &nbsp; </span>
                    <span>{{
                      item.type == 2 ? item.live ? item.live : 0 : item.recordStudy ? item.recordStudy : 0
                    }}%</span>
                  </div>
                </div>
              </div>
              <!-- 专属 -->
              <div v-if="(item.type == 1 || item.type == 16) &&
                item.coufig &&
                item.coufig.length > 0
              " class="typeList">
                <div class="type-name">专属</div>
                <div class="type-coufig">
                  <span v-for="(coufig, coufigindex) in item.coufig" :key="coufigindex">
                    {{ coufigindex ? "·" : "" }}{{ coufig }}
                  </span>
                </div>
              </div>
            </div>
            <div class="btnBox shrink0">
              <!-- <div class="colleges-learn shrink0" @click="join(item)">查看课程</div> -->
              <div v-if="item.type == 1 || item.type == 16" class="colleges-learn shrink0 a" @click="seeClass(item)">
                查看套餐
              </div>
              <div v-else class="right_btn">
                <div class="right_btn_class" @click="seeCourse(item)">
                  查看课程<i class="iconfont icon-xiangxia" :class="{ xiangshang: item.open }" />
                </div>
                <div class="right_btn_course">
                  <div v-if="item.courseDocFlag == 1" @click="kczl(item)">
                    课程资料
                  </div>
                  <div v-if="item.courseTestFlag == 1" @click="kccs(item)">
                    课程测试
                  </div>
                </div>
              </div>
              <!-- <div
                class="colleges-learn shrink0"
                v-else
                @click="seeCourse(item)"
              >
                查看课程<i
                  class="iconfont icon-xiangxia"
                  :class="{ xiangshang: item.open }"
                ></i>
              </div> -->
            </div>
          </div>
          <div v-show="item.open" class="bottom_box bottom_boxs">
            <div v-for="(child, childIndex) in item.chapterList" v-if="item.type == 10" :key="childIndex"
              class="chapter_item">
              <div class="left_box">
                <div class="title_box">
                  <div v-if="item.type != 10" :class="child.isLiveStatus == 0 ? 'tag tags' : 'tag'">
                    <template v-if="child.isLiveStatus == 1">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon1.png" alt="">
                      <span>直播中</span>
                    </template>
                    <template v-else-if="child.isLiveStatus == 0">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon2.png" alt="">
                      <span>未开始</span>
                    </template>
                    <template v-else-if="child.isLiveStatus == 2">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon3.png" alt="">
                      <span style="color: #ffac87">回放生成</span>
                    </template>
                    <template v-else-if="child.isLiveStatus == 3">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon4.png" alt="">
                      <span>回放</span>
                    </template>
                  </div>
                  <div class="title">
                    {{ child.name }}
                  </div>
                </div>
                <div class="time">
                  <img class="time_img" src="@/assets/img/homeSeventh/clockAndWatch.png" alt="">
                  <span>{{
                    child.startTime | format('yyyy.MM.dd HH:mm')
                  }}</span>
                  <!-- <span>-</span>
                  <span>{{ child.endTime | format("HH:mm") }}</span> -->
                </div>
                <div v-if="item.type != 10" class="progress_box">
                  <div class="progress_bar">
                    <div class="schedule" :style="`width: ${child.studyRate}%`" />
                  </div>
                  <span class="text">{{ child.studyRate }}%</span>
                </div>
              </div>
              <div class="right_box">
                <div v-if="child.teachList && child.teachList.length > 0" class="btn_item"
                  @click.stop="goData(child, 2, item)">
                  随堂讲义
                </div>
                <div v-if="child.testList && child.testList.length > 0" class="btn_item" @click.stop="cgeckTask(child)">
                  随堂测试
                </div>
                <div v-if="child.resourceList && child.resourceList.length > 0" class="btn_item"
                  @click.stop="goData(child, 1, item)">
                  随堂资料
                </div>
              </div>
            </div>
            <div v-for="(child, childIndex) in item.chapterList" v-if="item.type == 2" :key="childIndex"
              class="chapter_item">
              <div class="left_box">
                <div class="title_box">
                  <div v-if="item.type == 2" :class="child.isLiveStatus == 0 ? 'tag tags' : 'tag'">
                    <template v-if="child.isLiveStatus == 1">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon1.png" alt="">
                      <span>直播中</span>
                    </template>
                    <template v-else-if="child.isLiveStatus == 0">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon2.png" alt="">
                      <span>未开始</span>
                    </template>
                    <template v-else-if="child.isLiveStatus == 2">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon3.png" alt="">
                      <span style="color: #ffac87">回放生成</span>
                    </template>
                    <template v-else-if="child.isLiveStatus == 3">
                      <img class="tag_img" src="@/assets/img/homeSeventh/playIcon4.png" alt="">
                      <span>回放</span>
                    </template>
                  </div>
                  <div class="title" @click.stop="check(child, item)">
                    {{ child.name }}
                  </div>
                </div>
                <div class="time">
                  <img class="time_img" src="@/assets/img/homeSeventh/clockAndWatch.png" alt="">
                  <span>{{
                    child.startTime | format('yyyy.MM.dd HH:mm')
                  }}</span>
                  <!-- <span>-</span>
                  <span>{{ child.endTime | format("HH:mm") }}</span> -->
                </div>
                <div class="progress_box">
                  <div class="progress_bar">
                    <div class="schedule" :style="`width: ${child.studyRate}%`" />
                  </div>
                  <span class="text">{{ child.studyRate }}%</span>
                </div>
              </div>
              <div class="right_box">
                <div class="btn_item" @click.stop="check(child, item)">
                  观看课程
                </div>
                <div v-if="child.teachList && child.teachList.length > 0" class="btn_item"
                  @click.stop="goData(child, 2, item)">
                  随堂讲义
                </div>
                <div v-if="child.testList && child.testList.length > 0" class="btn_item" @click.stop="cgeckTask(child)">
                  随堂测试
                </div>
                <div v-if="child.resourceList && child.resourceList.length > 0" class="btn_item"
                  @click.stop="goData(child, 1, item)">
                  随堂资料
                </div>
              </div>
            </div>
            <div v-for="(childs, childIndexs) in item.chapterList" v-if="item.type == 3" :key="childIndexs"
              class="zhang">
              <div class="zhang_item">
                <img class="time_img" src="@/assets/img/homeSeventh/dc.png" alt="">
                <div class="zhang_item_name">{{ childs.name }}</div>
                <div v-if="!childs.isshow" class="zhang_item_last" @click="ifshow(childs, 1, item)">
                  展开 <i class="iconfont icon-xiangxia1" />
                </div>
                <div v-if="childs.isshow" class="zhang_item_last" @click="ifshow(childs, 2, item)">
                  收回 <i class="iconfont icon-xiangshang2" />
                </div>
              </div>
              <div v-for="(child, childIndex) in gchapterVOList" v-show="childs.isshow" :key="childIndex"
                class="chapter_item chapter_items">
                <div class="left_box">
                  <div class="title_box">
                    <div class="title" @click.stop="checks(child, item, childIndexs)">
                      {{ child.name }}
                    </div>
                  </div>
                  <!-- <div class="time">
                  <img
                    class="time_img"
                    src="@/assets/img/homeSeventh/clockAndWatch.png"
                    alt=""
                  />
                  <span>{{
                    child.startTime | format("yyyy.MM.dd HH:mm")
                  }}</span>
                  <span>-</span>
                  <span>{{ child.endTime | format("HH:mm") }}</span>
                </div>-->
                  <div class="progress_box">
                    <div class="progress_bar">
                      <div class="schedule" :style="`width: ${child.studyRate}%`" />
                    </div>
                    <span class="text">{{ child.studyRate }}%</span>
                  </div>
                </div>
                <div class="right_box">
                  <div class="btn_item" @click.stop="checks(child, item, childIndexs)">
                    观看课程
                  </div>
                  <div v-if="child.teachList && child.teachList.length > 0" class="btn_item"
                    @click.stop="goData(child, 2, item)">
                    随堂讲义
                  </div>
                  <div v-if="child.testList && child.testList.length > 0" class="btn_item"
                    @click.stop="cgeckTask(child)">
                    随堂测试
                  </div>
                  <div v-if="child.resourceList && child.resourceList.length > 0" class="btn_item"
                    @click.stop="goData(child, 1, item)">
                    随堂资料
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
      :page-sizes="[8, 16, 24, 40]" :page-size="pageSize" :pager-count="5" background
      layout="total, sizes, prev, pager, next" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <el-dialog class="new_bac" title="课程资料" :visible.sync="dialogVisible">
      <course-data :newproductLine="newproductLine" :newkcId="newkcId" v-if="dialogVisible" :id="courId"
        :type="courseType" :is-purchase="true" />
    </el-dialog>
    <el-dialog class="new_bac" title="课程测试" :visible.sync="dialogVisibles">
      <course-task :newproductLine="newproductLine" :newkcId="newkcId" v-if="dialogVisibles" :id="courId"
        :type="courseType" :is-purchase="true" />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName :
            ""
            }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import { getInfo } from '@/api/cookies'
import {
  getClassCourseListById,
  selectStuCourseChapterDetails,
  selectChapterRateBykeIdStudentIdGroupId
} from '@/api/mykecheng'
import Vue from 'vue'
import { showTypeEnums } from '@/api/emun'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import CourseData from '@/components/Know/course/courseData.vue'
import courseTask from '@/components/Know/course/courseTask.vue'
import { courseCheckPlay, getPrivateClassData } from '@/api/know'
export default {
  components: { CourseData, courseTask },
  data() {
    return {
      list: [],
      smllList: [],
      tuid: 8,
      tel: '',
      //  1知识套餐
      type: 3,
      total: 0,
      pageSize: 8,
      pageNum: 1,
      userId: '',
      showTypeEnums: showTypeEnums,
      userInfo: null,
      classRate: {},
      btnInfo: 0,
      courseType: null,
      courseTypes: null,
      courId: null,
      gchapterVOList: [],
      courId: null,
      dialogVisible: false,
      dialogVisibles: false,
      kmsg: {},
      lookDeatil: null,
      showLookDetail: false,
      kcbClassId: '',
      newproductLine: '1',
      newkcId: ''
    }
  },
  created() {
    this.userInfo = getInfo()
    this.userId = localStorage.getItem('userId')
    this.classesId = this.$route.query.id
    // this.kcbClassId = this.$route.query.kcbClassId

    if (!this.userId) {
      Vue.prototype.goLoginView('mustBack')
      return
    }
    // 调用我的课程接口
    this.getList()
    this.getPrivateClassData()
  },
  methods: {
    /* 计算有效期 */
    calculateTheValidityPeriod(time) {
      const currentTime = new Date()
      time = time ? time.toString().replace(/-/g, '/') : time
      const targetTime = new Date(time)
      const difference = targetTime.getTime() - currentTime.getTime()
      if (difference < 0) {
        return '已过期'
      }
      const year = Math.floor(difference / 1000 / 60 / 60 / 24 / 365)
      const days =
        Math.floor(difference / 1000 / 60 / 60 / 24) - Math.floor(year * 365)
      // if ( year > 0 && days > 0 ) {
      //     return year + '年' + days + '天'
      // } else if ( year <= 0 && days > 0 ) {
      //     return days + '天'
      // }
      return year + '年' + days + '天'
    },
    // 查看套餐
    toCourse(item) {
      this.$router.push({
        path: `/seventhPage/courseLearningRate?kcbClassId=${item.kcbClassId}&addTime=${item.addTime}&type=${item.type}&id=${item.coursesId}&className=${item.name}&itype=${item.type}`
      })
    },
    // 我的课程接口
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      await getClassCourseListById(
        this.userId,
        this.type == 0 ? 3 : this.type,
        this.pageSize,
        this.pageNum
      ).then((res) => {
        if (res.code != 500) {
          for (const item of res.rows) {
            const data = []
            if (item.type == 1 || item.type == 16) {
              if (item.isData == 0) {
                data.push('专属资料')
              }
              if (item.isQuestion == 0) {
                data.push('专属题库')
              }
              if (item.isExamination == 0) {
                data.push('专属考场')
              }
              if (item.isTeaching == 0) {
                data.push('专属教材')
              }
              if (item.isReference == 0) {
                data.push('专属教服')
              }
              item.coufig = data
            }
          }
          res.rows.map((item) => {
            this.$set(item, 'info', false)
            this.$set(item, 'rate', {
              liveSevenStudy: 0,
              liveDkStudy: 0,
              roomDkStudy: 0,
              videoStudy: 0
            })
          })

          this.list = res.rows
          this.total = res.total
        }
      })
      loading.close()
    },
    async getPrivateClassData() {
      await getPrivateClassData().then(res => {

      })
    },
    /* 切换每页条数 */
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 标签页切换
    clik() {
      this.pageNum = 1
      this.list = []
      this.pageSize = 8
      this.getList()
    },
    // 进入课堂
    join(row) {
      const validityTime = new Date(row.validityTime) // 有效期截至时间
      const currentTime = new Date() // 当前时间
      if (row.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (row.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (validityTime.getTime() < currentTime.getTime()) {
        this.$message({
          message: '课程已到期,请联系老师',
          type: 'error'
        })
        return
      }
      if (row.isFreeze == 1) {
        this.$message({
          message: '课程已冻结,请联系老师',
          type: 'error'
        })
        return
      }
      Vue.prototype.goTypeDetail(
        row.type,
        row.type == 16 ? row.kcbClassId : row.coursesId,
        row.isSkip,
        undefined,
        undefined,
        row.productLine
      )
    },
    // 去选课
    allopenclass() {
      this.$router.push(`/allcurriculum/typeclass`)
    },
    changeTabs(index) {
      this.type = index
      this.btnInfo = index
      this.pageNum = 1
      this.list = []
      this.pageSize = 8
      this.getList()
    },
    // 查看套餐
    seeClass(item) {
      if (item.productLine === '5') {
        const routeUrl = this.$router.resolve({
          path: '/VIP/oneToOneClass',
          query: {
            id: item.coursesId
          }
        })
        window.open(routeUrl.href, '_blank')
        return
      }
      if (item.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (item.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (item.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (this.calculateTheValidityPeriod(item.validityTime) == '已过期') {
        this.$message.error('课程已到期')
        return
      }

      // if (item.isHavePrivateClass == 1) {
      // this.$router.push({
      //   path: `/seventhPage/courseLearningRate?kcbClassId=${item.kcbClassId}&id=${item.coursesId}&className=${item.name}&type=${item.type}`
      // })
      // } else {
      this.$router.push({
        path: `/seventhPage/curriculumName?kcbClassId=${item.kcbClassId}&addTime=${item.addTime}&id=${item.coursesId}&className=${item.name}&type=${item.type}`
      })
      // }
    },
    // 查看课程
    seeCourse(item) {
      if (item.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (item.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (item.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (this.calculateTheValidityPeriod(item.validityTime) == '已过期') {
        this.$message.error('课程已到期')
        return
      }
      this.courseType = item.types
      this.courseTypes = item.type
      this.courId = item.coursesId
      if (!item.open) {
        this.list.forEach((e) => {
          this.$set(e, 'open', false)
          if (e.chapterList && e.chapterList.length >= 0) {
            e.chapterList.forEach((el) => {
              this.$set(el, 'isshow', false)
            })
          }
        })
      }
      this.$set(item, 'open', !item.open)
      if (item.chapterList && item.chapterList.length) return
      const data = {
        type: item.type,
        courseId: item.coursesId,
        studentId: this.userInfo.id
      }
      selectStuCourseChapterDetails(data).then((res) => {
        if (res.code !== 0) return
        // res.data.forEach((e) => {
        //   if (e.gchapterVOList && e.gchapterVOList.length > 0) {
        //     e.isshow = false;
        //   }
        // });
        this.$set(item, 'chapterList', res.data)
      })
    },
    /* 随堂资料 */
    goData(item, type, items) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }

      window.open(
        `/data/dataPackageDetails?courId=${this.courId}&courseType=${this.courseType
        }&type=${this.courseType == 1 ? 2 : 1}&docType=${type}&ChatId=${item.id
        }&groupId=${items.type == 3 ? item.groupId : undefined}`,
        '_blank'
      )
    },
    // 课堂作业
    cgeckTask(item) {
      const title = item.testList[0].sjName
      const sId = item.testList[0].cContactid
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExams(title, sId, 2, 1, true, res.data.paper.seeNum)
        }
      })
    },
    ifshow(row, i, item) {
      this.gchapterVOList = []
      if (i == 1) {
        selectChapterRateBykeIdStudentIdGroupId({
          keId: this.courId,
          groupId: row.id,
          studentId: this.userInfo.id
        }).then(async (res) => {
          if (res.code !== 0) return
          await item.chapterList.forEach((e) => {
            e.isshow = false
          })
          this.gchapterVOList = res.msg
          row.isshow = true
        })
      } else {
        row.isshow = false
      }
      this.$forceUpdate()
    },
    // 观看重播按钮  切换课程章节播放(直播)
    check(item, group) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }

      // if (this.isFreeze == 1) {
      //   this.$message.error('课程已冻结')
      //   return
      // }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }

      const sub = {
        id: item.id,
        startTime: item.startTime,
        chapterName: item.name,
        classProductLine: group.productLine
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // })
      courseCheckPlay({
        courseType: this.courseType,
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`
          })
          window.open(routeUrl.href, '_blank')
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    // 观看回放（录播）
    checks(item, group, index) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }

      if (this.isOutsourcing == 1) {
        this.ifDialog = true
        this.dialogList = item
      } else {
        const sub = {
          id: item.id,
          uservideoConfig: item.videoConfig,
          groupId: this.courId,
          classId: null,
          typebs: 2,
          courseId: item.courseId,
          chapterName: item.name,
          classProductLine: group.productLine
        }
        const data = Vue.prototype.export(sub)
        courseCheckPlay({
          courseType: this.courseType,
          userId: this.userInfo.id,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {

            this.$router.push(`/dianbofangs?${data}&openIndex=${index}`)
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      }
    },
    kczl(item) {
      if (item.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (item.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (item.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (this.calculateTheValidityPeriod(item.validityTime) == '已过期') {
        this.$message.error('课程已到期')
        return
      }
      this.courseType = item.types
      this.courId = item.coursesId
      this.newproductLine = item.productLine
      this.newkcId = item.kcId
      this.dialogVisible = true
    },
    closeLook() {
      this.showLookDetail = false
    },
    kccs(item) {
      if (item.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (item.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (item.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (this.calculateTheValidityPeriod(item.validityTime) == '已过期') {
        this.$message.error('课程已到期')
        return
      }
      this.courseType = item.types
      this.courId = item.coursesId
      this.newproductLine = item.productLine
      this.newkcId = item.kcId
      this.dialogVisibles = true
    }
  }
}
</script>
<style lang="less" scoped>
.foxRight {
  background: #fff;
  padding-bottom: 20px;
}

.tab_box {
  width: 412px;
  height: 38px;
  border-radius: 6px;
  padding: 4px;
  background-color: #f5f7f9;
  display: flex;
  justify-content: space-around;
  position: relative;

  .btn_item {
    width: 132px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    color: #333333;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  .on {
    color: #4a6af0;
    font-weight: 800;
  }

  .sliding_block {
    width: 132px;
    height: 30px;
    border-radius: 6px;
    background-color: #ffffff;
    position: absolute;
    left: 10px;
    transition: 200ms;
  }
}

.list {
  width: 100%;
  margin-top: 24px;

  /* 班型 */
  .classView {
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.34);
    min-height: 133px;
    padding: 14px 20px;
    border-radius: 8px;

    .box {
      .item-img {
        width: 192px;
        height: 108px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }

      .item-centent {
        height: 108px !important;
      }
    }

    .bottom_box {
      width: 764px;

      background-color: #f9f9f9;
      border-radius: 8px;
      margin-top: 15px;

      .chapter_item {
        height: 110px;
        width: 732px;
        padding: 16px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;

        .left_box {
          max-width: 336px;

          .title_box {
            margin-bottom: 9px;
            display: flex;
            align-items: center;

            .tag {
              display: inline-block;
              padding: 2px 8px;
              height: 22px;
              border-radius: 0 8px 0 8px;
              background: linear-gradient(132deg, #fff3eb 0%, #ffedeb 100%);
              margin-right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ff6e2d;
              font-size: 12px;
              flex-shrink: 0;

              .tag_img {
                width: 11px;
                margin-right: 3px;
              }
            }

            .title {
              color: hsl(0, 0%, 20%);
              // width: 370px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }

          .time {
            color: #aaaaaa;
            font-size: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 19px;

            .time_img {
              width: 14px;
              height: 14px;
              margin-right: 6px;
              flex-shrink: 0;
            }
          }

          .progress_box {
            width: 400px !important;
            display: flex;
            align-items: center;

            .progress_bar {
              width: 400px !important;
              height: 8px;
              background-color: #e5e5e5;
              border-radius: 10px;

              .schedule {
                background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
                height: 8px;
                width: 0;
                border-radius: 10px;
              }
            }

            .text {
              font-size: 12px;
              color: #2871ff;
              margin-left: 8px;
            }
          }
        }

        .right_box {
          display: flex;
          align-items: center;

          .btn_item {
            width: 80px;
            height: 30px;
            margin-bottom: 20px;
            border-radius: 4px;
            border: 1px solid #4a6af0;
            color: #4a6af0;
            font-size: 13px;
            text-align: center;
            line-height: 30px;
            margin-left: 14px;
            cursor: pointer;
          }
        }
      }

      .chapter_item:last-child {
        border-bottom: 0;
      }
    }

    .bottom_boxs {
      background-color: #fff;

      .chapter_item {
        background-color: #f9f9f9;
      }

      .zhang_item {
        display: flex;
        align-items: center;
        padding: 14px 0;

        .time_img {
          display: block;
          width: 14px;
          height: 12px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        .zhang_item_name {
          width: 660px;
        }

        .zhang_item_last {
          flex-shrink: 0;
          width: 40px;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #aaaaaa;
          cursor: pointer;

          .iconfont {
            font-size: 12px;
          }
        }
      }

      .chapter_items {
        height: 82px;

        .left_box {
          .title_box {
            // margin-bottom: 27px;
          }

          .title {
            max-width: 336px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }

        .progress_box {
          margin-top: 17px;
        }
      }
    }
  }

  /* 课程 */
  .courView {
    .item-img {
      width: 192px;
      height: 128px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
    }

    .item-centent {
      height: 128px !important;
    }
  }

  .item {
    width: 100%;
    background: #ffffff;
    position: relative;
    margin-bottom: 24px;

    .item-img {
      flex-shrink: 0;
      margin-right: 14px;
    }

    .item-centent {
      width: 327px;

      // width: 100%;
      .item_name-box {
        display: flex;
        align-items: center;

        .item-tips {
          width: 78px !important;
          height: 20px;
          border-radius: 4px;
          border: 1px solid #ff7b1c;
          font-size: 12px;
          color: #ff7b1c;
          text-align: center;
          line-height: 18px;
          margin-right: 8px;
        }

        .item-text {
          width: 400px;
          height: 22px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
        }
      }

      .tips {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ff7b1c;
        line-height: 12px;

        .item-tips {
          background-color: #fff2e9;
          padding: 4px 7px;
          border-radius: 4px;
          margin-right: 8px;
        }
      }
    }

    .btnBox {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .icon-xiangxia {
        font-size: 10px;
        margin-left: 6px;
        display: inline-block;
      }

      .xiangshang {
        transform: rotate(180deg);
        transition: 200ms;
      }

      .right_btn {
        flex-shrink: 0;
      }

      .right_btn_class {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #626e7c;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon-xiangxia {
          font-size: 10px;
          margin-left: 6px;
          display: inline-block;
        }

        .xiangshang {
          transform: rotate(180deg);
          transition: 200ms;
          transform-origin: 50% 50%;
        }
      }

      .right_btn_course {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;

        >div {
          margin-right: 14px;
          width: 93px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #1061ff;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #1061ff;
        }

        >div:last-child {
          margin-right: 0;
        }
      }
    }

    .colleges-ter {
      // position: absolute;
      // bottom: 13px;
      margin-bottom: 18px;
    }

    .learning_rate {
      display: flex;

      .rate_item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-size: 14px;

        .img {
          width: 15px;
          margin-right: 6px;
        }

        .text {
          > :nth-child(1) {
            color: #777777;
          }

          > :nth-child(2) {
            color: #4a6af0;
          }
        }
      }
    }
  }

  /* 专属 */
  .typeList {
    margin-top: 24px;
    /* position: absolute;
    bottom: 13px; */
    display: flex;
    align-items: center;

    .type-name {
      width: 44px;
      height: 24px;
      background: #c4c4c4 linear-gradient(134deg, #616f79 0%, #232733 100%);
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffe2b5;
      line-height: 24px;
      text-align: center;
    }

    .type-coufig {
      height: 22px;
      background: linear-gradient(118deg, #fcefd0 0%, #ffda9b 100%);
      border-radius: 0px 4px 4px 0px;
      opacity: 1;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #834917;
      line-height: 22px;
      padding: 0px 8px;
    }
  }

  .flex-c {
    position: relative;
  }

  /* 课程有效期： */
  .item-validPeriod {
    position: absolute;
    z-index: 2;
    top: -20px;
    right: 5px;
    width: 148px;
    height: 28px;
    border-radius: 0 0 4px 4px;
    background-color: #e3effd;
    color: #626e7c;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
  }

  .semicircle {
    width: 75px;
    height: 6px;
    border-radius: 0 6px 0 0;
    background-color: #a8bfdb;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: -20px;
  }

  .course_type {
    width: 56px;
    height: 21px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0 8px 0;
    color: #ffffff;
    font-size: 11px;
    text-align: center;
    line-height: 21px;
  }
}

::-webkit-scrollbar {
  width: 0 !important;
}

.tags {
  background: linear-gradient(132deg, #ececec 0%, #e5e5e5 100%) !important;
  color: #777777 !important;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

::v-deep .new_bac {
  .el-dialog {
    background: url(~@/assets/img/study/1.png);
    background-size: 100% 100%;
    border-radius: 20px !important;
    width: 690px;
    height: 494px;

    .el-dialog__title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 22px;
      color: #1D315B;
    }

    .el-dialog__close {
      font-size: 23px;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
  }
}
</style>
